import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import AboutUs from "../components/about-us/about-us";
import {Helmet} from "react-helmet";

import SocialImage from '../images/social/LogoNeu.jpg';

export default () => (
    <>
        <Helmet htmlAttributes={{"lang": "de"}}>
            {/*Primary Meta Tags*/}
            <title>Kronschl&auml;ger Reisen — &Uuml;ber Uns</title>
            <meta name="title" content="Kronschl&auml;ger Reisen — &Uuml;ber Uns"/>
            <meta name="description"
                  content="Kronschl&auml;ger Reisen - ein etabliertes Unternehmen, das mit Verl&auml;sslichkeit punktet"/>

            {/*Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.kronschlaeger-reisen.at"/>
            <meta property="og:title" content="Kronschl&auml;ger Reisen — &Uuml;ber Uns"/>
            <meta property="og:description"
                  content="Kronschl&auml;ger Reisen - ein etabliertes Unternehmen, das mit Verl&auml;sslichkeit punktet"/>
            <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

            {/* Google Bot */}
            <meta name="robots" content="index,follow"/>
        </Helmet>
        <Header/>
        <AboutUs/>
        <Footer/>
    </>
);