import React from "react";
import * as styles from './about-us.module.css';


// https://freefrontend.com/css-timelines/
// https://codepen.io/letsbleachthis/pen/YJgNpv
export default class AboutUs extends React.Component {
    render() {
        return (
            <div className={"container"}>
                <div className={styles.container}>
                    <div className={styles.timeline}>
                        <div className={styles.step}>
                            <h3>Buslenker Inland / Fernreisen</h3>
                            <p>Sammeln von Erfahrungen mit unterschiedlichen Bussen in verschiedenen L&auml;ndern mit
                                Fahrg&auml;sten jeder Altersgruppe.
                            </p></div>
                        <div className={styles.step}>
                            <h3>Firmengr&uuml;ndung mit Taxi, Mietwagen und Sch&uuml;lertransport</h3>
                            <p>
                                Weg in die Selbstst&auml;ndigkeit mit mehreren Angestellten.
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Reisebusse bis 30 Sitze</h3>
                            <p>
                                Anschaffung eines Reisebusses in gefragter Gr&ouml;&szlig;e. Für gr&ouml;&szlig;ere
                                Gruppen werden weiterhin entsprechende Busse angemietet.
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Komfortfahrzeug für Gesch&auml;ftspartner</h3>
                            <p>
                                Der Stolz unserer Flotte für den bequemen Transport auf Gesch&auml;ftsreisen.
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Komfortabler 20 Sitzer</h3>
                            <p>
                                Nicht umsonst nennen wir unseren Flottenzuwachs "den kleine Luxus".
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Reisebusse bis 50 Sitze</h3>
                            <p>
                                Anschaffung eines modernen und gut ausgestatteten 50er Busses für l&auml;ngere Reisen
                                und gr&ouml;&szlig;ere Gruppen.
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Umweltfreundlicher Elektro-Neunsitzer</h3>
                            <p>
                                Anschaffung eines modernen, elektrisch angetriebenen Neunsitzers. So wird die Umwelt bei
                                Schüler- und Krankentransporten als auch Ausflügen geschont.
                            </p>
                        </div>
                        <div className={styles.step}>
                            <h3>Heute</h3>
                            <p>
                                Etabliertes Unternehmen, das mit Verl&auml;sslichkeit punktet
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}